import { ERROR_MESSAGE } from '../constants';

export const initialData = {
  form: {
    is_otopack: false,
    claimer_email: '',
    claimer_phone_number: '',
    claimer_only_phone_number: '',
    claimer_type: [],
    stt_id: '',
    claim_type: [],
    attachment_photo_ktp_url: [],
    attachment_photo_bank_url: [],
    attachment_invoice_url: [],
    attachment_photo: [],
    attachment_photo_demage_packet: [],
    attachment_photo_packet_url: [],
    attachment_stnk_photo_front: {},
    attachment_stnk_photo_back: {},
    captcha: '',
  },
  validation: {
    claimer_email: false,
    claimer_phone_number: false,
    claimer_only_phone_number: false,
    claimer_type: false,
    stt_id: false,
    claim_type: false,
    attachment_photo_ktp_url: false,
    attachment_photo_bank_url: false,
    attachment_invoice_url: false,
    attachment_photo: false,
    attachment_photo_demage_packet: false,
    attachment_photo_packet_url: false,
    attachment_stnk_photo_front: false,
    attachment_stnk_photo_back: false,
    captcha: false,
  },
  error: {
    claimer_email: false,
    claimer_phone_number: false,
    claimer_only_phone_number: false,
    claimer_type: false,
    stt_id: false,
    claim_type: false,
    attachment_photo_ktp_url: false,
    attachment_photo_bank_url: false,
    attachment_invoice_url: false,
    attachment_photo: false,
    attachment_photo_demage_packet: false,
    attachment_photo_packet_url: false,
    attachment_stnk_photo_front: false,
    attachment_stnk_photo_back: false,
    captcha: false,
  },
  isUserValid: {
    checklist: false, // if user click checkbox
    isEveryField: false, // if every field not empty
    isNotErrorField: false, // if every field not error
    isValid: false, // if user click checkbox and field not empty
    isFormValid: false, // if user click checkbox and field not empty and every form is valid
    showModal: false, // show modal
    isFinalValidation: false, // if user click ya, sesuai
  },
  errorMessage: {
    claimer_email: ERROR_MESSAGE.IS_CLAIMER_EMAIL_EMPTY,
    claimer_only_phone_number: ERROR_MESSAGE.IS_ONLY_PHONE_NUMBER_EMPTY,
    claimer_type: ERROR_MESSAGE.IS_CLAIMER_TYPE_EMPTY,
    stt_id: ERROR_MESSAGE.IS_STT_EMPTY,
    claim_type: ERROR_MESSAGE.IS_CLAIM_TYPE_EMPTY,
    attachment_photo_ktp_url: ERROR_MESSAGE.IS_ATTACHMENT_KTP,
    attachment_photo_bank_url: ERROR_MESSAGE.IS_ATTACHMENT_BANK,
    attachment_invoice_url: ERROR_MESSAGE.IS_ATTACHMENT_INVOICE,
    attachment_photo: ERROR_MESSAGE.IS_ATTACHMENT_INVOICE,
    attachment_photo_demage_packet: ERROR_MESSAGE.IS_ATTACHMENT_DEMAGE_PACKET,
    attachment_photo_packet_url: ERROR_MESSAGE.IS_ATTACHMENT_PHOTO_PACKET_URL,
    attachment_stnk_photo_front: ERROR_MESSAGE.IS_ATTACHMENT_STNK_PHOTO_FRONT_EMPTY,
    attachment_stnk_photo_back: ERROR_MESSAGE.IS_ATTACHMENT_STNK_PHOTO_BACK_EMPTY,
  },
};
