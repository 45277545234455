<template>
  <ModalVue
    v-if="showModal"
    :use-icon-close="false"
    width="500px"
  >
    <template>
      <!-- container Modal -->
      <div class="modal-content">
        <!-- icon -->
        <div class="modal-content__icon">
          <img
            :src="`/images/icon_confirmation.svg`"
            alt=""
          >
        </div>
        <!-- Heading -->
        <div class="modal-content__heading">
          <h1>Data yang Dikirim Sudah Sesuai?</h1>
        </div>
        <!-- description -->
        <div class="modal-content__description">
          <h1>Pastikan data yang Anda kirim telah benar & mengikuti <span @click="navigateToClaim">syarat dan ketentuan klaim</span></h1>
        </div>
        <!-- button confirmation -->
        <div class="modal-content__button-confirmation">
          <Button
            :custom-padding="true"
            type-class="outline-red"
            text="Cek Kembali"
            @click="$emit('click', 'check-again')"
          />

          <Button
            :custom-padding="true"
            type-class="red"
            text="Ya, Sesuai"
            @click="$emit('click', 'next')"
          />
        </div>
      </div>
    </template>
  </ModalVue>
</template>

<script>
import Button from "@/components/new-button/Button.vue";
import ModalVue from '@/components/new-modal/Modal.vue';
import { navigateToClaim } from '@/pages/new-claim/app/helpers';

export default {
  components: {
    ModalVue,
    Button
  },
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    navigateToClaim,
  }
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
