<template>
  <div class="base-content">
    <!-- Foto Barang for demage packet -->
    <div
      class="do-flex"
      style="align-items: flex-end"
    >
      <h2>Foto Barang</h2>
      <span class="star">*</span>
    </div>
    <div class="base-content__foto-collections">
      <div
        v-for="(src, idx) in data"
        :key="idx"
      >
        <div class="img-item">
          <img
            :src="src"
            alt=""
          >
          <div
            class="icon-trash"
            @click="$emit('remove', idx)"
          >
            <img
              :src="`/images/trashIcon.svg`"
              alt=""
            >
          </div>
        </div>
      </div>
      <div
        v-if="data.length < 6"
        :class="['base-content__foto-collections__wrapper ', {borderError: isError}]"
        :tabindex="tabindex"
        @keypress="onEnter"
        @click="addPhotoDemagePacket"
      >
        <input
          ref="file2"
          style="display: none"
          accept="image/jpg, image/png, image/jpeg"
          type="file"
          @change="$emit('change', $event)"
          @click="(event) => (event.target.value = null)"
        >
        <span class="plus">+</span>
      </div>
    </div>

    <!-- description -->
    <div
      v-if="!isError"
      class="base-content__foto-collections__field-desc"
    >
      <span>Upload foto barang dengan 6 sisi(Sisi kanan, kiri, atas, bawah, depan,
        dan belakang)</span>
    </div>

    <!-- if error -->
    <div
      v-if="isError"
      class="base-content__foto-collections__field-error"
    >
      <span>{{ errorMessage }}</span>
    </div>
    <!-- End Foto Barang Demage packet -->
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: String,
      default: '-1',
    },
  },
  data() {
    return {
      data: this.value,
    };
  },
  watch: {
    value(val) {
      this.data = val;
    },
  },
  methods: {
    addPhotoDemagePacket(event) {
      this.$refs.file2.click();
      event.target.value = '';
    },
    onEnter(event) {
      event.preventDefault();
      if (event.key === 'Enter' || event.key === ' ') {
        this.addPhotoDemagePacket(event);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/style.scss";
.base-content {
  padding: 0px !important;

  &__foto-collections {
    display: flex;
    gap: 10px;
    position: relative;

    @include for-size(desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @include for-size(tablet-portrait) {
      display: flex;
    }

    @include for-size(xmobile) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include for-size(mobile) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 5px;
    }

    @media (max-width: 320px) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    &__wrapper {
      cursor: pointer;
      padding: 35px 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed $color-gray-pensive-5;
      width: 100px;
      height: 100px;

      @include for-size(big-desktop) {
        width: 115px;
        height: 115px;
      }

      @include for-size(xmobile) {
        width: 95px;
        height: 95px;
      }

      @include for-size(mobile) {
        width: 90px;
        height: 90px;
      }

      .plus {
        font-size: 30px;
        font-weight: 400;
        color: $color-gray-shade;
      }
    }

    &__field-desc {
      > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $color-gray-shade;
      }
    }

    &__field-error {
      > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $color-base;
      }
    }
  }
}

.img-item {
  width: 100px;
  height: 100px;

  @include for-size(big-desktop) {
    width: 115px;
    height: 115px;
  }

  @include for-size(xmobile) {
    width: 95px;
    height: 95px;
  }

  @include for-size(mobile) {
    width: 100%;
    height: 90px;
  }

  overflow: hidden;
  border-radius: 8px;
  position: relative;

  > img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
}

.icon-trash {
  top: 40%;
  right: 40%;
  position: absolute;
  padding: 5px;
  cursor: pointer;
}

.borderError {
  border: 2px dashed $color-base;
}
</style>
