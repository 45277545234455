<template>
  <section class="captcha">
    <div class="captcha__section">
      <img :src="captchaImg" width="213" height="85" alt="Captcha Image" />
      <div class="captcha__section__refresh-button" @click="refreshCaptcha">
        <img
          src="/images/ics_o_sync.svg"
          width="15"
          height="15"
          alt="Refresh Icon"
        />
        <span v-show="!isLoading">
          Refresh
        </span>
      </div>
    </div>
    <div class="captcha__input-section">
      <div class="captcha__input-section__label">
        Kode Captcha
      </div>
      <Input
        name="captcha"
        placeholder="Masukkan kode captcha"
        custom-placeholder="placeholder-lg"
        custom-class="captcha-class"
        :value="value"
        :class-name="decideErrorBorder()"
        :tabindex="tabindex"
        @input="onInput"
        @focus="onFocus"
      />
      <div v-show="isError" class="field-error-message">
        <img :src="`/images/icon_danger_triangles.svg`" alt="Icon Warning" />
        <span class="error-message-red">{{ errorMessage }}</span>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '@/components/new-input/Input.vue';

export default {
  name: 'InputCaptcha',
  components: { Input },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    isError: {
      type: Boolean,
      required: false,
    },
    errorMessage: {
      type: String,
      default: 'this field is required',
    },
    captchaImg: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: String,
      default: '-1',
    },
  },
  data() {
    return {};
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
    onFocus() {
      this.$emit('focus');
    },
    decideErrorBorder() {
      if (this.isError) {
        return 'border-error input-full';
      }
      return 'input-full';
    },
    refreshCaptcha() {
      if (!this.isLoading) {
        this.$emit('refresh');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/style.scss';

.captcha {
  margin-top: 14px;

  &__section {
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      margin-right: 9.5px;

      @include for-size(mobile) {
        margin-right: 5.5px;
      }
    }

    &__refresh-button {
      display: flex;
      align-items: center;
      flex-direction: row;
      cursor: pointer;

      img {
        margin-right: 7.5px;
      }

      span {
        font-size: 12px;
        line-height: 18px;
        font-weight: 600;
        color: $color-base;
      }
    }
  }

  &__input-section {
    margin-top: 12px;

    &__label {
      color: $color-grey;
      font-size: 14px;
      line-height: 21px;
      font-weight: 600;
      margin-bottom: 8px;

      &::after {
        content: '*';
        color: red;
      }
    }
  }
}

.error-message-red {
  color: $color-red-6;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-left: 5px;
}
</style>
