/**
 * @param {Object} settings - settings
 * @param {File} settings.file - image file/blob
 * @param {Number} settings.maxSize - maximal image pixel (width or height)
*/
export const resizeImage = function (settings) {
  const { file } = settings;
  const { maxSize } = settings;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');
  const dataURItoBlob = function (dataURI) {
    const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
      ? window.atob(dataURI.split(',')[1])
      : decodeURI(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (let i = 0; i < max; i++) { ia[i] = bytes.charCodeAt(i); }
    return new Blob([ia], { type: mime });
  };
  const resize = function () {
    let { width } = image;
    let { height } = image;
    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL(file.type);
    return dataURItoBlob(dataUrl);
  };
  return new Promise(((resolve, reject) => {
    if (!file.type.match(/image.*/)) {
      reject(new Error('Not an image'));
      return;
    }
    reader.onload = function (readerEvent) {
      image.onload = function () { return resolve(resize()); };
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  }));
};
