export const dataDocument = [
  {
    document: 'Foto KTP ',
    inParentheses: '(Pengirim atau Penerima)',
  },
  {
    document: 'Cover buku rekening bank ',
    inParentheses: 'pelanggan (atas nama KTP yang di lampirkan)',
  },
  {
    document: 'Foto invoice /nota harga ',
    inParentheses: 'barang yang dikirimkan',
  },
  {
    document: 'Untuk klaim kerusakan wajib melampirkan dokumentasi berupa ',
    inParentheses: 'foto kerusakan fisik kiriman dan foto packing kiriman',
  },
];

export const dataWithInsurance = [
  `Seluruh biaya dan proses asuransi ditanggung oleh pihak asuransi yang
    bekerjasama dengan Lion Parcel`,

  `Dikenakan biaya asuransi sebesar 0,4 % dari harga
  barang yang diasuransikan (dibayarkan saat booking
  STT)`,

  `Kehilangan/Kerusakan barang akan di-cover hingga maksimum Rp
    1.000.000.000 (Satu Milyar Rupiah)`,
];

export const dataWithoutInsurance = [
  'Tidak dikenakan biaya asuransi',

  `Nominal penggantian klaim maksimum hingga 10 kali biaya pengiriman yang tercantum di STT (sesuai PP No.15 tahun 2013)`,
];
