import CLAIMRepository from '../api/claim';
import '../types';

class CLAIMUseCase {
  constructor() {
    /** @type {CLAIMRepository} */
    this.api = new CLAIMRepository();
  }

  /**
     * @param payload {Object}
     * @param key {String}
     * @param status {string}
     * @return {Promise <{data: CLAIM[], err: Error}}
     */

  async create(payload, key) {
    const { data, err } = await this.api.create(payload, key);
    return { data, err };
  }

  /**
     * @param id {number}
     * @param status {status}
     * @return {Promise<{data: CLAIM[], err: Error}}
     */

  async getId(id) {
    const { data, err } = await this.api.getId(id);
    return { data, err };
  }

  /**
     * @return {Promise<{data: Blob, err: Error}}
     */

  async getCaptcha() {
    const { data, err } = await this.api.getCaptcha();
    return { data, err };
  }
}

export default new CLAIMUseCase();
