import InternalAPI from '@/app/baseAxios';
import '../types';
import { getCaptchaKey, setCaptchaKey } from '@/misc/Cookies';

/** @borrows InternalAPI */

export default class CLAIMRepository extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.submit_claim = '/v1/claim/me';
    this.endpoint = '/v1/claim';
  }

  /**
     * @param payload {object}
     * @param status {string}
     * @return {Promise<{data: CLAIM[], err: Error}}
     */

  async create(payload) {
    this.$axios.defaults.timeout = 60000;
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.post(`${this.submit_claim}`, payload, {
        headers: {
          'Captcha-Key': getCaptchaKey(),
          'Content-Type': 'multipart/form-data',
          Authorization: process.env.VUE_APP_TOKEN_URL,
        },
      });
      if (res.data && res.status === 200) {
        return { data: res.data, err: null };
      }
      return { data: null, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response?.data?.desc || err.response?.data?.message) };
    } finally {
      this.$axios.defaults.timeout = 1000 * (+process.env.VUE_APP_TIMEOUT_BE || 12);
    }
  }

  async getId(id) {
    try {
      /** @type {AxiosResponse<Object>} */
      const res = await this.$axios.get(`${this.endpoint}/${id}`);
      if (res.data && res.status === 200) {
        return { data: res.data, err: null };
      }
      return { data: null, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response?.data?.message) };
    }
  }

  async getCaptcha() {
    try {
      /** @type {AxiosResponse<Blob>} */
      const res = await this.$axios.get(this.endpoint, {
        responseType: 'blob',
        headers: {
          'Captcha-Key': getCaptchaKey(),
        },
      });
      if (res.status === 200) {
        setCaptchaKey(res.headers['captcha-key']);
        return { data: window.URL.createObjectURL(new Blob([res.data])), err: null };
      }
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response?.data?.desc || err.response?.data?.message) };
    }
  }
}
