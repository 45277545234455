<template>
  <div>
    <div
      class="do-flex"
      style="align-items: flex-end;"
    >
      <h2 class="img_upload_label">
        {{ label }}
      </h2>
      <span
        v-if="required"
        class="star"
      >*</span>
      <span
        v-else
        class="optional"
      >(Opsional)</span>
    </div>
    <div class="wrapper">
      <!-- default input for upload -->
      <!-- if user not yet to upload -->
      <div
        v-if="!isDataimg"
        :class="['wrapper__input', { borderError: error }]"
        :tabindex="tabindex"
        @keypress="onEnter"
        @click="handleClick"
      >
        <!-- container with border -->
        <div class="wrapper__input__upload">
          <!-- flex column -->
          <div>
            <!-- icon -->
            <div class="wrapper__input__upload__icon">
              <img
                :src="`/images/UploadIcon.svg`"
                alt=""
              >
            </div>
            <!-- title -->
            <div class="wrapper__input__upload__title">
              <span>{{ title }}</span>
            </div>
            <!-- desc -->
            <div class="wrapper__input__upload__desc">
              <span>Format .png .jpg .jpeg sampai {{ maxSize }} MB</span>
            </div>
          </div>
        </div>
      </div>
      <!-- container with image -->
      <div
        v-else
        class="wrapper__container"
      >
        <div class="wrapper__container__img">
          <img
            :src="dataImg[0]"
            alt=""
          >
        </div>
        <div class="wrapper__container__icon">
          <div
            class="change-image"
            @click="changeImg"
          >
            <span>Ubah Foto</span>
          </div>
          <div
            class="trash-icon"
            @click="close()"
          >
            <img
              :src="`/images/trashIcon.svg`"
              alt=""
            >
          </div>
        </div>
      </div>
      <input
        ref="file"
        accept="image/jpg, image/png, image/jpeg"
        type="file"
        style="display: none"
        @focus="$emit('focus', $event)"
        @change="onChange"
        @click="onClick"
      >
      <!-- desc -->
      <div class="wrapper__desc">
        <span v-show="description && !error">{{ description }}</span>
        <div
          v-if="error"
          class="field-error-message"
        >
          <img
            :src="`/images/icon_danger_triangles.svg`"
            alt="icon-danger"
          >
          <span class="error-message-red">{{ errorMessageImage }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { resizeImage } from '@/misc/imageResize';

export default {
  model: {
    prop: 'dataImg',
    event: 'value',
  },
  props: {
    title: {
      type: String,
      default: 'Upload KTP',
    },
    description: {
      type: String,
      default: '',
    },
    dataImg: {
      type: Array,
      default: () => [],
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    maxSize: {
      type: Number,
      default: 99,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: String,
      default: '-1',
    },
  },
  data() {
    return {
      isDataimg: false,
      src: '',
      error: this.isError,
      errorMessageImage: this.errorMessage,
    };
  },
  watch: {
    isError(val) {
      this.error = val;
    },
    errorMessage(val) {
      this.errorMessageImage = val;
    },
  },
  methods: {
    handleClick() {
      this.$refs.file.click();
      this.errorMessageImage = this.errorMessage;
    },
    close() {
      this.isDataimg = false;
      this.src = '';
      this.$emit('value', [this.src]);
    },
    changeImg() {
      this.$refs.file.click();
    },
    async onChange(event) {
      const MAX_SIZE = this.maxSize * 1024 * 1024;
      if (event.target.files[0].size <= MAX_SIZE) {
        this.error = false;
        this.isDataimg = true;
        const file = event.target.files[0];
        if (file.size > 600000) { // is file size more than 600KB
          const resizedImage = await resizeImage({ // it will compress
            file,
            maxSize: 650, // pixel, width or height
          });
          const url = URL.createObjectURL(resizedImage);
          this.src = url;
          this.$emit('value', [this.src, resizedImage]);
        } else {
          const url = URL.createObjectURL(file);
          this.src = url;
          this.$emit('value', [this.src, file]);
        }
      } else {
        this.error = true;
        this.errorMessageImage = `Foto ${this.label} tidak boleh melebihi dari ${this.maxSize} MB`;
      }
    },
    onClick(event) {
      event.target.value = null;
      this.$emit('click', this.$event);
    },
    onEnter(event) {
      event.preventDefault();
      if (event.key === 'Enter' || event.key === ' ') {
        this.handleClick();
      }
    },
  },
};

</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
