export const validationWithRegex = {
  EMAIL: /^([A-z0-9-_.]+)@([A-z0-9-_.]+)\.([A-z]{2,5})$/i,
  STT: /([A-z0-9-])/g,
  HANDPHONE: /^(8)(\d{8,11}$)/g,
};

export const onCheckbox = (method, id) => {
  const element = document.querySelector(`#${id}`);
  if (method === "disabled-unchecked") {
    element?.classList.remove("red");
    element?.nextSibling?.classList.remove("checklist");
  }
  
  // back to default checkbox
  if (method === 'remove') {
    element?.classList.remove('red');
    element?.classList.add('white');
  }
  // to checklist
  if (method === 'add') {
    element?.classList.add('red');
    element?.classList.remove('white');
  }
};

export const useBorderError = (id, method, isPrefix, without = ['#text']) => {
  const element = document.querySelector(`.${id}-class`);
  const childElement = [...element.childNodes].filter(d => !without.includes(d.nodeName))
  if (method === 'add' && isPrefix) {
    childElement[1]?.classList?.add('border-error');
  }
  if (method === 'remove' && isPrefix) {
    childElement[1]?.classList?.remove('border-error');
  }
  if (method === 'add' && !isPrefix) {
    childElement[1].classList.add('border-error');
  }
  if (method === 'remove' && !isPrefix) {
    childElement[1].classList.remove('border-error');
  }
};
