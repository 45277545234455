<template>
  <div class="base-input">
    <label :for="label">
      <div class="base-input__label">
        <span class="title">{{ label }}</span>
        <span
          v-if="required"
          class="star"
        >*</span>
        <span
          v-else
          class="optional"
        >(Opsional)</span>
      </div>
    </label>
    <base-input
      v-bind="$attrs"
      :custom-class="$attrs.name + '-' + 'class'"
      :custom-placeholder="customPlaceholder"
      :type-input="typeInput"
      :input-custom-class="error ? 'border-error' : ''"
      tabindex="0"
      @input="handleInput"
      @focus="$emit('focus')"
    />
    <div
      v-show="error"
      class="field-error-message"
    >
      <img
        :src="`/images/icon_danger_triangles.svg`"
        alt="Icon Warning"
      >
      <span class="error-message-red">{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/new-input/Input.vue';

export default {
  components: {
    BaseInput,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    customPlaceholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: '',
    },
    typeInput: {
      type: String,
      default: 'text',
    },
  },
  data() {
    return {
      error: this.isError,
    };
  },
  watch: {
    isError(val) {
      this.error = val;
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('input', event, this.$attrs.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.scss";

.base-input {
  padding-top: 24px;

  &__label {
    margin-bottom: 8px;
  }

  .star {
    color: $color-base;
    margin-left: 4px;
  }

  & ::v-deep .input-wrapper {
    display: flex;

    &.prefix {
      display: flex !important;
      align-items: center;
      text-align: left !important;
    }

    & .padding-input-prefix {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding-left: 8px !important;
    }

    & .prefix {
      position: relative;
      padding: 7.5px 12px;
    }
  }

  & ::v-deep .input-breakpoint-style {
    &.border-error {
      border:1px solid $color-base !important;
    }
  }
}

.title {
  font-size: 14px;
  color: $color-base-text;
  font-weight: 600;
}

.optional {
  margin-left: 4px;
  color: $color-gray-shade;
  font-size: 14px;
}

.error-message-red {
  color: $color-red-6;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin-left: 5px;
}

.input-breakpoint-style {
  border: 1px solid red;
}

.border-red {
  border: 1px solid red;
}

</style>
