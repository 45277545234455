import {
  DAMAGE_TYPE, ERROR_MESSAGE, MISSING_TYPE, OVERDUE_TYPE,
} from '../constants/index';
import { validationWithRegex } from '../utils';

export const handleSwitch = (...args) => {
  if (args.length === 2) {
    const label = args[0];
    const error = args[1];
    switch (label) {
      case 'stt_id':
        error.stt_id = false;
        break;
      case 'claimer_type':
        error.claimer_type = false;
        break;
      case 'only_phone_number':
        error.claimer_only_phone_number = false;
        break;
      case 'claim_type':
        error.claim_type = false;
        break;
      case 'email':
        error.claimer_email = false;
        break;
      case 'attachment_photo_bank_url':
        error.attachment_photo_bank_url = false;
        break;
      case 'attachment_photo_ktp_url':
        error.attachment_photo_ktp_url = false;
        break;
      case 'attachment_invoice_url':
        error.attachment_invoice_url = false;
        break;
      case 'attachment_photo':
        error.attachment_photo = false;
        break;
      case 'attachment_stnk_photo_front':
        error.attachment_stnk_photo_front = false;
        break;
      case 'attachment_stnk_photo_back':
        error.attachment_stnk_photo_back = false;
        break;
      case 'captcha':
        error.captcha = false;
        break;
    }
  }

  if (args.length === 3) {
    const value = args[0];
    const label = args[1];
    const form = args[2];
    switch (label) {
      case 'nomor-resi':
        form.stt_id = value;
        break;
      case 'email':
        form.claimer_email = value;
        break;
      case 'handphone':
        form.claimer_only_phone_number = value;
        break;
      case 'telephone':
        form.claimer_phone_number = value;
        break;
    }
  }
  return args;
};

const validateMBGType = (form, value) => {
  if (form.claim_type.length && form.claim_type[0].value.toLowerCase() === OVERDUE_TYPE) {
    return true;
  }
  return value;
};

const validateEmail = (form, value) => {
  if (form.claim_type.length && form.claim_type[0].value.toLowerCase() === OVERDUE_TYPE) {
    return true;
  }
  return value.match(
    validationWithRegex.EMAIL,
  );
};

const _validateSttWithRegex = (inputFromStt, form) => inputFromStt && inputFromStt.length <= 17
      && form.stt_id.length === inputFromStt.length
      && inputFromStt.filter(str => str === '-').length < 3;

export const handleValidationForm = (...args) => {
  const isUserValid = args[0];
  const form = args[1];
  const validation = args[2];
  const isError = args[3];
  const errorMessage = args[4];
  const id = args[5];

  isUserValid.isFormValid = false;
  const inputFromStt = form.stt_id.match(validationWithRegex.STT);
  const checkSttWithRegex = _validateSttWithRegex(inputFromStt, form);

  validation.stt_id = Boolean(checkSttWithRegex);
  validation.claimer_email = !!validateEmail(form, form.claimer_email);
  validation.claimer_only_phone_number = !!form.claimer_only_phone_number
    .match(validationWithRegex.HANDPHONE);
  validation.attachment_photo_bank_url = !!validateMBGType(form, form.attachment_photo_bank_url[1]);
  validation.attachment_photo_ktp_url = !!validateMBGType(form, form.attachment_photo_ktp_url[1]);
  validation.attachment_stnk_photo_front = form.is_otopack ? !!form.attachment_stnk_photo_front[0] : true;
  validation.attachment_stnk_photo_back = form.is_otopack ? !!form.attachment_stnk_photo_back[0] : true;

  const isFormAlready = validation.stt_id
      && validation.claimer_only_phone_number
      && validateMBGType(form, validation.claimer_email)
      && validateMBGType(form, validation.attachment_photo_bank_url)
      && validateMBGType(form, validation.attachment_photo_ktp_url);

  if (!validation.stt_id) {
    isError.stt_id = true;
    errorMessage.stt_id = ERROR_MESSAGE.IS_STT;
  }
  if (!validation.claimer_email) {
    isError.claimer_email = true;
    errorMessage.claimer_email = ERROR_MESSAGE.IS_CLAIMER_EMAIL;
  }
  if (!validation.claimer_only_phone_number) {
    isError.claimer_only_phone_number = true;
    errorMessage.claimer_only_phone_number = ERROR_MESSAGE.IS_ONLY_PHONE_NUMBER;
  }

  if (id.toLowerCase() === OVERDUE_TYPE && isFormAlready) {
    isUserValid.isFormValid = true;
  }
  if (id.toLowerCase() === MISSING_TYPE && isFormAlready) {
    validation.attachment_invoice_url = form.is_otopack ? true : !!form.attachment_invoice_url[1];
    if (
        validation.attachment_invoice_url &&
        validation.attachment_stnk_photo_back && 
        validation.attachment_stnk_photo_front
      ) {
      isUserValid.isFormValid = true;
    }
  }

  if (id.toLowerCase() === DAMAGE_TYPE && isFormAlready) {
    validation.attachment_invoice_url = form.is_otopack ? true : !!form.attachment_invoice_url[1];
    validation.attachment_photo_demage_packet = form.is_otopack ? true : form.attachment_photo_demage_packet.length === 6;
    if (
      validation.attachment_invoice_url && 
      validation.attachment_photo_demage_packet && 
      validation.attachment_stnk_photo_back && 
      validation.attachment_stnk_photo_front
    ) {
      isUserValid.isFormValid = true;
    }
  }
  return args;
};

const _attachmentValidation = (form, errorMessage, isError, isErrorSize, id) => {
  errorMessage.attachment_photo_bank_url = isError
    .attachment_photo_bank_url ? ERROR_MESSAGE.IS_ATTACHMENT_BANK_EMPTY : '';

  errorMessage.attachment_photo_ktp_url = isError
    .attachment_photo_ktp_url ? ERROR_MESSAGE.IS_ATTACHMENT_KTP_EMPTY : '';

  errorMessage.attachment_invoice_url = isError
    .attachment_invoice_url ? ERROR_MESSAGE.IS_ATTACHMENT_INVOICE_EMPTY : '';

  errorMessage.attachment_stnk_photo_front = isError.attachment_stnk_photo_front ? ERROR_MESSAGE.IS_ATTACHMENT_STNK_PHOTO_FRONT_EMPTY : '';

  errorMessage.attachment_stnk_photo_back = isError.attachment_stnk_photo_back ? ERROR_MESSAGE.IS_ATTACHMENT_STNK_PHOTO_BACK_EMPTY : '';

  errorMessage.captcha = isError.captcha ? ERROR_MESSAGE.IS_CAPTCHA_EMPTY : '';

  if (
    form.attachment_photo_demage_packet.length < 6
    && id === 'Rusak'
  ) {
    errorMessage.attachment_photo_demage_packet = ERROR_MESSAGE.IS_ATTACHMENT_DEMAGE_PACKET_EMPTY;
  } else if (
    form.attachment_photo_demage_packet.length !== 0
    && isErrorSize
    && id.toLowerCase() === DAMAGE_TYPE
  ) {
    errorMessage.attachment_photo_demage_packet = ERROR_MESSAGE.IS_ATTACHMENT_DEMAGE_PACKET;
  } else {
    isError.attachment_photo_demage_packet = false;
  }
};

export const handleCheckEveryField = (...args) => {
  const isUserValid = args[0];
  const form = args[1];
  const isError = args[2];
  const errorMessage = args[3];
  const isErrorSize = args[4];
  const id = args[5];

  isUserValid.isNotErrorField = false;
  isError.claimer_email = !form.claimer_email;
  isError.claimer_only_phone_number = !form.claimer_only_phone_number;
  isError.stt_id = !form.stt_id;
  isError.claimer_type = form.claimer_type.length < 1;
  isError.claim_type = form.claim_type.length < 1;
  isError.attachment_photo_bank_url = !form.attachment_photo_bank_url[1];
  isError.attachment_photo_ktp_url = !form.attachment_photo_ktp_url[1];
  isError.attachment_invoice_url = !form.is_otopack && !form.attachment_invoice_url[1];
  isError.captcha = !form.captcha;
  isError.attachment_photo_demage_packet = !form.is_otopack;
  isError.attachment_stnk_photo_front = form.is_otopack ? !form.attachment_stnk_photo_front[0] : false;
  isError.attachment_stnk_photo_back = form.is_otopack ? !form.attachment_stnk_photo_back[0] : false;

  _attachmentValidation(form, errorMessage, isError, isErrorSize, id);

  const onCheckErrorEveryField = !isError.stt_id
      && !isError.claimer_type
      && !isError.claimer_only_phone_number
      && !isError.claim_type
      && !isError.captcha
      && validateMBGType(form, !isError.claimer_email)
      && validateMBGType(form, !isError.attachment_photo_bank_url)
      && validateMBGType(form, !isError.attachment_photo_ktp_url)
      && !isError.attachment_stnk_photo_back
      && !isError.attachment_stnk_photo_front;

  if (id.toLowerCase() === OVERDUE_TYPE && onCheckErrorEveryField) {
    isUserValid.isNotErrorField = true;
  }
  if (
    id.toLowerCase() === MISSING_TYPE
    && onCheckErrorEveryField
    && isError.attachment_invoice_url === false
  ) {
    isUserValid.isNotErrorField = true;
  }
  if (
    id.toLowerCase() === DAMAGE_TYPE
    && onCheckErrorEveryField
    && !isError.attachment_invoice_url
    && !isError.attachment_photo_demage_packet
  ) {
    isUserValid.isNotErrorField = true;
  }
  return args;
};

export const navigateToClaim = () => {
  window.open('/klaim');
};
