<template>
  <div
    :tabindex="tabindex"
    @keypress="onEnter"
  >
    <input
      :disabled="disabled"
      :id="id"
      :ref="id"
      type="checkbox"
      class="white"
      @click="$emit('click', $event.target)"
    >
    <div class="checklist" />
  </div>
</template>

<script>
// this component also used in src/pages/mobile-app/recipient-form/RecipientForm.vue
// please make sure to update the component in both places to not break the functionality
export default {
  props: {
    tabindex: {
      type: String,
      default: '-1',
    },
    id: {
      type: String,
      default: 'base-checkbox'
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    onEnter(event) {
      event.preventDefault();
      if (event.key === 'Enter' || event.key === ' ') {
        this.$refs[this.id].click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.scss";

div {
  position: relative;
  height: fit-content;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  outline: none;
  /* Not removed via appearance */
  margin: 0;
  cursor: pointer;
  font: inherit;
  width: 1.15em;
  height: 1.15em;
  border-radius: 4px;
  transform: translateY(-0.075em);
  display: grid;
  place-content:center;
}

.checklist{
  /* content: ""; */
  margin-right: 10px;
  border-radius: 0.15em;
  background: white;
  width: 0.65em;
  height: 0.65em;
  top:2px;
  left: 4px;
  position: absolute;
  clip-path: polygon(83% 7%, 91% 8%, 95% 15%, 58% 68%, 43% 85%, 6% 59%, 8% 51%, 17% 50%, 41% 66%, 67% 30%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.white {
  background: white !important;
  border: 1px solid $color-gray-pensive-5;
}

.red {
  background: $color-base !important;
}
</style>
