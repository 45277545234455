<template>
  <div class="base-content">
    <transition name="bounce">
      <div
        v-if="isErrorAlertOpen"
        class="error-alert"
      >
        <div class="error-alert__card">
          <div class="error-alert__card__desc">
            <p>{{ errorAlertMessage }}</p>
          </div>
          <div
            class="error-alert__card__close"
            @click="toggleErrorAlert"
          >
            <img
              src="/images/x.svg"
              alt=""
            >
          </div>
        </div>
      </div>
    </transition>
    <!-- Modal -->
    <Modal
      :show-modal="showModal"
      @click="handlebuttonShowModal"
    />

    <div
      v-if="isLoading"
      class="loader-claim"
    >
      <div class="loader-claim__box">
        <div>
          <Loader
            color-class="l-grey"
            position-left="32%"
          />
        </div>
      </div>
    </div>

    <!-- breadcrumb -->
    <div class="base-content__breadcrumb">
      <BreadCrumb />
    </div>
    <!-- form -->
    <div class="base-content__form">
      <!-- form claim -->
      <div class="base-content__form__form-claim">
        <h1>Form Klaim</h1>
        <div class="base-content__form__form-claim__otopack-checkbox">
          <CheckBox
            id="otopack-checkbox"
            tabindex="0"
            @click="onCheckOtopack"
          />
          <div class="checkbox-note">
            <span>Barang yang akan saya klaim menggunakan layanan OTOPACK</span>
            <span class="otopack-note">Jika menggunakan layanan lain, silahkan lanjutkan proses klaim tanpa mencentang pertanyaan diatas</span>
          </div>
        </div>
        <Dropdown
          label="Tipe Klaim"
          placeholder="Tipe Klaim"
          :options="getClaimType"
          :value="form.claim_type"
          :required="true"
          :is-remove-shadow="false"
          :error-message="errorMessage.claim_type"
          :is-error="error.claim_type"
          @focus="handleOnFocus('claim_type')"
          @input="onInput"
        />
        <Input
          name="nomor-resi"
          placeholder="Masukkan nomor resi atau STT"
          label="Nomor Resi/STT"
          type-input="text"
          custom-placeholder="placeholder-lg"
          :required="true"
          :error-message="errorMessage.stt_id"
          :is-error="error.stt_id"
          @focus="handleOnFocus('stt_id')"
          @input="inputFormatValue"
        />
        <Dropdown
          label="Klaimer"
          :options="optionsClaimerType"
          :value="form.claimer_type"
          :required="true"
          :is-remove-shadow="true"
          :is-error="error.claimer_type"
          :disabled="isClaimMBG"
          :error-message="errorMessage.claimer_type"
          placeholder="Pilih tipe klaimer"
          @focus="handleOnFocus('claimer_type')"
        />

        <Input
          name="handphone"
          placeholder="Masukkan Nomor handphone dan diawali angka 8"
          :label="decidePhoneNumberLabel"
          prefix="+62"
          type-input="number"
          custom-placeholder="placeholder-lg"
          :required="true"
          :error-message="errorMessage.claimer_only_phone_number"
          :is-error="error.claimer_only_phone_number"
          @input="inputFormatValue"
          @focus="handleOnFocus('only_phone_number')"
        />
        <span
          v-show="!error.claimer_only_phone_number"
          class="input-desc"
        >{{ decidePhoneNumberDesc }}</span>
        <Input
          name="telephone"
          placeholder="Masukkan nomor telepon "
          label="Nomor Telepon"
          prefix="+62"
          custom-placeholder="placeholder-lg"
          error-message="Nomor telepon salah, cek kembali"
          :is-error="false"
          @input="inputFormatValue"
        />
        <div v-show="isClaimTypeNotOverdue()">
          <Input
            name="email"
            placeholder="Masukkan email Anda"
            label="Email"
            type-input="text"
            custom-placeholder="placeholder-lg"
            :required="true"
            :error-message="errorMessage.claimer_email"
            :is-error="error.claimer_email"
            @input="inputFormatValue"
            @focus="handleOnFocus('email')"
          />
          <div class="base-content__form__form-claim__upload">
            <div>
              <ImageUpload
                v-model="form.attachment_photo_bank_url"
                label="Buku Tabungan"
                :max-size="2"
                :required="true"
                title="Upload Halaman Depan Buku Tabungan"
                description="Pastikan foto tertera nama & nomor rekening dengan jelas"
                :error-message="errorMessage.attachment_photo_bank_url"
                :is-error="error.attachment_photo_bank_url"
                tabindex="0"
                @click="handleOnFocus('attachment_photo_bank_url')"
              />
            </div>

            <div>
              <ImageUpload
                v-model="form.attachment_photo_ktp_url"
                title="Upload KTP"
                label="KTP"
                :max-size="2"
                :required="true"
                :error-message="errorMessage.attachment_photo_ktp_url"
                :is-error="error.attachment_photo_ktp_url"
                tabindex="0"
                @click="handleOnFocus('attachment_photo_ktp_url')"
              />
            </div>
          </div>
        </div>
        <!-- if Rusak | Hilang -->
        <div v-if="form.claim_type.length > 0">
          <div v-if="form.claim_type[0].value.toLowerCase() === MISSING_TYPE && !form.is_otopack">
            <div>
              <div>
                <ImageUpload
                  v-model="form.attachment_invoice_url"
                  title="Upload Bukti Invoice"
                  label="Bukti Invoice"
                  :max-size="2"
                  :required="true"
                  :error-message="errorMessage.attachment_invoice_url"
                  :is-error="error.attachment_invoice_url"
                  tabindex="0"
                  @click="handleOnFocus('attachment_invoice_url')"
                />
                <ImageUpload
                  v-model="form.attachment_photo_packet_url"
                  title="Upload Foto Barang"
                  label="Foto Barang"
                  description="Mohon upload foto jika barang anda hilang sebagian"
                  :max-size="2"
                  :error-message="errorMessage.attachment_photo_packet_url"
                  :is-error="error.attachment_photo_packet_url"
                  tabindex="0"
                  @click="handleOnFocus('attachment_photo_packet_url')"
                />
              </div>
            </div>
          </div>
          <div v-if="form.claim_type[0].value.toLowerCase() === DAMAGE_TYPE && !form.is_otopack">
            <div>
              <div>
                <div>
                  <ImageUpload
                    v-model="form.attachment_invoice_url"
                    title="Upload Bukti Invoice"
                    label="Bukti Invoice"
                    :max-size="2"
                    :required="true"
                    :error-message="errorMessage.attachment_invoice_url"
                    :is-error="error.attachment_invoice_url"
                    tabindex="0"
                    @click="handleOnFocus('attachment_invoice_url')"
                  />
                </div>
              </div>
              <!-- Foto Barang for demage packet -->
              <MultiUploadImage
                :value="form.attachment_photo_demage_packet"
                :is-error="error.attachment_photo_demage_packet"
                :error-message="errorMessage.attachment_photo_demage_packet"
                tabindex="0"
                @remove="removePhoto"
                @change="handlePhotoDemagePacket"
              />
              <!-- end foto barang demage -->
            </div>
          </div>
          <div
            v-if="form.is_otopack"
            class="otopack-stnk-wrapper"
          >
            <h2 class="otopack-stnk-wrapper__label">
              Foto STNK (2 Sisi)
            </h2>
            <div class="otopack-stnk-wrapper__fields">
              <STNKUploadImage
                :value="form.attachment_stnk_photo_front"
                tabindex="0"
                note="Sisi Depan"
                :is-error="error.attachment_stnk_photo_front"
                :error-message="errorMessage.attachment_stnk_photo_front"
                @change="value => onChangeSTNKImage(value, 'front')"
                @remove="() => onRemoveSTNKImage('front')"
                @click="handleOnFocus('attachment_stnk_photo_front')"
              />
              <STNKUploadImage
                :value="form.attachment_stnk_photo_back"
                tabindex="0"
                note="Sisi Belakang"
                :is-error="error.attachment_stnk_photo_back"
                :error-message="errorMessage.attachment_stnk_photo_back"
                @change="value => onChangeSTNKImage(value, 'back')"
                @remove="() => onRemoveSTNKImage('back')"
                @click="handleOnFocus('attachment_stnk_photo_back')"
              />
            </div>
          </div>
        </div>

        <Captcha
          v-model="form.captcha"
          :is-error="error.captcha"
          :error-message="errorMessage.captcha"
          :is-loading="isLoadingCaptcha"
          :captcha-img="captchaImage"
          tabindex="0"
          @focus="handleOnFocus('captcha')"
          @refresh="getCaptcha"
        />

        <div class="base-content__form__form-claim__footer">
          <CheckBox
            id="submit-checkbox"
            tabindex="0"
            @click="handleCheckOnCheckbox"
          />
          <p>
            Dengan melakukan pengajuan klaim, saya telah menyetujui
            <span @click="navigateToClaim">syarat & ketentuan klaim</span>
            yang berlaku
          </p>
          <div class="submit">
            <Button
              class="custom-padding"
              :custom-padding="true"
              :type-class="ButtonSubmitType"
              :text="buttonText"
              @click="createNewClaim"
            />
          </div>
        </div>
      </div>
      <!-- comp track claim -->
      <TrackingForm />
    </div>
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb.vue';
import Input from '@/pages/new-claim/components/Input';
import ImageUpload from '@/pages/new-claim/components/imageUpload';
import CheckBox from '@/pages/new-claim/components/Checkbox';
import Button from '@/components/new-button/Button.vue';
import ClaimUseCase from '@/pages/new-claim/app/usecase/claim.js';
import Modal from '@/pages/new-claim/components/Modal';
import Dropdown from '@/pages/new-claim/components/Dropdown';
import { onCheckbox, useBorderError } from '@/pages/new-claim/app/utils';
import {
  handleCheckEveryField,
  handleSwitch,
  handleValidationForm,
  navigateToClaim,
} from '@/pages/new-claim/app/helpers';
import {
  ERROR_MESSAGE, CLAIM_TYPE, OVERDUE_TYPE, MISSING_TYPE, DAMAGE_TYPE,
} from '@/pages/new-claim/app/constants';
import TrackingForm from '@/pages/new-claim/form-claim/TrackingForm';
import MultiUploadImage from '@/pages/new-claim/components/MultiUploadImage';
import STNKUploadImage from '@/pages/new-claim/components/STNKUploadImage';
import { initialData } from '@/pages/new-claim/app/initialData';
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import { resizeImage } from '@/misc/imageResize';
import Captcha from '@/components/input-captcha/Captcha.vue';
import Loader from '@/components/new-loader/Loader.vue';

export default {
  components: {
    BreadCrumb,
    ImageUpload,
    Input,
    CheckBox,
    Button,
    Modal,
    Dropdown,
    TrackingForm,
    MultiUploadImage,
    STNKUploadImage,
    Captcha,
    Loader,
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  mixins: [mixinMetaInfo],
  data() {
    return {
      ERROR_MESSAGE,
      buttonText: 'Ajukan Klaim',
      ButtonSubmitType: 'disable',
      showModal: false,
      isErrorSize: false,
      photoDemageCollection: [],
      OVERDUE_TYPE,
      MISSING_TYPE,
      DAMAGE_TYPE,
      isLoading: false,
      isClaimMBG: false,
      optionsClaimType: [
        {
          label: 'Hilang',
          value: 'Hilang',
        },
        {
          label: 'Rusak',
          value: 'Rusak',
        },
      ],
      optionsClaimerType: [
        {
          label: 'Penerima',
          value: 'Penerima',
        },
        {
          label: 'Pengirim',
          value: 'Pengirim',
        },
      ],
      form: initialData.form,
      validation: initialData.validation,
      error: initialData.error,
      errorMessage: initialData.errorMessage,
      isUserValid: initialData.isUserValid,
      isErrorAlertOpen: false,
      errorAlertMessage: '',
      captchaImage: null,
      isLoadingCaptcha: false,
    };
  },
  computed: {
    isChangeClaimType() {
      return this.form.claim_type;
    },
    decidePhoneNumberLabel() {
      return this.isClaimTypeNotOverdue() ? 'Nomor Whatsapp' : 'Nomor Handphone';
    },
    decidePhoneNumberDesc() {
      return this.isClaimTypeNotOverdue()
        ? 'Pastikan nomor handphone tersambung ke WA'
        : 'Pastikan no handphone sesuai dengan Nomor Resi/STT';
    },
    getClaimType() {
      if (!this.form.is_otopack) {
        return [
          {
            label: 'Keterlambatan',
            value: 'Keterlambatan',
          }, ...this.optionsClaimType]
      } else {
        return this.optionsClaimType
      }
    },
  },
  watch: {
    form: {
      handler(dataForm) {
        const isFieldValid = dataForm.claimer_only_phone_number
          && dataForm.claimer_type.length > 0
          && dataForm.stt_id
          && dataForm.claim_type.length > 0
          && this.isMBGType(dataForm.claimer_email)
          && this.isMBGType(dataForm.attachment_photo_ktp_url[1])
          && this.isMBGType(dataForm.attachment_photo_bank_url[1])
          && Boolean(dataForm.is_otopack && dataForm.attachment_stnk_photo_front[0])
          && Boolean(dataForm.is_otopack && dataForm.attachment_stnk_photo_back[0]);

        // this must default is false
        this.isUserValid.isEveryField = false;
        this.isUserValid.isNotErrorField = false;
        this.isUserValid.isValid = false;
        this.isUserValid.isFormValid = false;
        this.isUserValid.showModal = false;
        this.isUserValid.isFinalValidation = false;

        // every change data checkbox must be default and button must be disable
        onCheckbox('remove', 'submit-checkbox');
        this.ButtonSubmitType = 'disable';

        if (isFieldValid && dataForm.claim_type[0].value.toLowerCase() === OVERDUE_TYPE) {
          this.isUserValid.isEveryField = true;
        }

        if (isFieldValid && dataForm.claim_type[0].value.toLowerCase() === MISSING_TYPE) {
          const onExtraCheck = (!dataForm.is_otopack && dataForm.attachment_invoice_url[1]) &&
            (dataForm.is_otopack && !dataForm.attachment_invoice_url[1]) &&
            dataForm.attachment_photo[1];
          if (onExtraCheck) {
            this.isUserValid.isEveryField = true;
          }
        }

        if (isFieldValid && dataForm.claim_type[0].value.toLowerCase() === DAMAGE_TYPE) {
          const onExtraCheck = (!dataForm.is_otopack && dataForm.attachment_invoice_url[1])
            && (dataForm.is_otopack && !dataForm.attachment_invoice_url[1])
            && dataForm.attachment_photo[1]
            && dataForm.attachment_photo_demage_packet.length > 6;
          if (onExtraCheck) {
            this.isUserValid.isEveryField = true;
          }
        }
      },
      deep: true,
    },
    isChangeClaimType() {
      // reset attachment
      this.form.attachment_invoice_url = [];
      this.form.attachment_photo = [];
      this.form.attachment_photo_demage_packet = [];
      onCheckbox('remove', 'submit-checkbox');
      this.ButtonSubmitType = 'disable';
      this.isUserValid.isFinalValidation = false;
    },
    error: {
      handler(err) {
        err.claimer_email
          ? useBorderError('email', 'add', false)
          : useBorderError('email', 'remove', false);
        err.claimer_only_phone_number
          ? useBorderError('handphone', 'add', true)
          : useBorderError('handphone', 'remove', true);
        err.stt_id
          ? useBorderError('nomor-resi', 'add', false)
          : useBorderError('nomor-resi', 'remove', false);
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.isSsr()) {
      window.scrollTo(0, 0);
    }
    this.resetData();
    this.getCaptcha();
  },
  methods: {
    navigateToClaim,
    onCheckOtopack() {
      if (this.form.is_otopack) {
        this.form.is_otopack = false
        onCheckbox('remove', 'otopack-checkbox')
      } else {
        if (
          this.form.claim_type.length &&
          this.form.claim_type[0].value.toLowerCase() === OVERDUE_TYPE
        ) {
          this.form.claim_type = []
        }
        this.form.is_otopack = true
        onCheckbox('add', 'otopack-checkbox')
      }
    },
    handlebuttonShowModal(type) {
      if (type === 'check-again') {
        this.isUserValid.isFinalValidation = false;
        onCheckbox('remove', 'submit-checkbox');
      } else {
        this.isUserValid.isFinalValidation = true;
        this.ButtonSubmitType = 'red';
      }
      this.showModal = false;
    },

    handleOnFocus(value) {
      handleSwitch(value, this.error);
    },

    inputFormatValue(value, name) {
      if (name === 'handphone' || name === 'telephone') {
        if (value.split('')[0] !== '8') {
          handleSwitch('', name, this.form);
          document.querySelector(`input[name="${name}"]`).value = '';
          return;
        }
      }
      handleSwitch(value, name, this.form);
    },

    removePhoto(value) {
      this.form.attachment_photo_demage_packet.splice(value, 1);
      this.photoDemageCollection.splice(value, 1);
    },

    async handlePhotoDemagePacket(val) {
      const MAX_SIZE = 2 * 1024 * 1024; // as 2MB
      const el = document.querySelector(
        '.base-content__foto-collections__wrapper',
      );
      if (val.target.files[0].size <= MAX_SIZE) {
        if (val.target.files[0].size > 600000) { // is file size more than 600KB
          const resizedImage = await resizeImage({ // it will compress
            file: val.target.files[0],
            maxSize: 650, // pixel, width or height
          });
          const src = URL.createObjectURL(resizedImage);
          this.photoDemageCollection.push(resizedImage);
          this.form.attachment_photo_demage_packet.push(src);
        } else {
          const src = URL.createObjectURL(val.target.files[0]);
          this.photoDemageCollection.push(val.target.files[0]);
          this.form.attachment_photo_demage_packet.push(src);
        }
        this.error.attachment_photo_demage_packet = false;
        this.isErrorSize = false;
        el.style.border = '2px dashed #E0E0E0';
      } else {
        this.error.attachment_photo_demage_packet = true;
        this.errorMessage.attachment_photo_demage_packet = ERROR_MESSAGE.IS_ATTACHMENT_DEMAGE_PACKET;
        this.isErrorSize = true;
        el.style.border = '2px dashed #d11f40';
      }
    },

    onChangeSTNKImage(value, type) {
      this.form[`attachment_stnk_photo_${type}`] = value
    },
    onRemoveSTNKImage(type) {
      this.form[`attachment_stnk_photo_${type}`] = []
    },
    // method for check every field is empty
    onCheckEveryField(id) {
      handleCheckEveryField(
        this.isUserValid,
        this.form,
        this.error,
        this.errorMessage,
        this.errorSize,
        id,
      );
    },

    handleValidation(id) {
      handleValidationForm(
        this.isUserValid,
        this.form,
        this.validation,
        this.error,
        this.errorMessage,
        id,
      );
    },

    handleCheckOnCheckbox(id) {
      if (id.classList[0] === 'white') {
        this.isUserValid.showModal = false;
        this.ButtonSubmitType = 'disable';
        this.isUserValid.checklist = true;
        onCheckbox('add', 'submit-checkbox');

        // use error message when field is empty
        const type = this.form.claim_type.length > 0 ? this.form.claim_type[0].value : '';
        this.onCheckEveryField(type);

        // check validation if every field is not empty
        if (this.isUserValid.isNotErrorField) {
          this.handleValidation(type);
        }

        // if every field is valid
        if (this.isUserValid.isFormValid) {
          this.isUserValid.showModal = true;
          this.showModal = true;

          // ready to send data
          if (this.isUserValid.isFinalValidation) {
            this.ButtonSubmitType = 'red';
          }
        }
      } else {
        onCheckbox('remove', 'submit-checkbox');
        this.isUserValid.checklist = false;
        this.ButtonSubmitType = 'disable';
      }
    },

    _setResponse(response) {
      if (response) {
        if (!response.err && response.data) {
          const payload = response.data.external_claim_id || response.data.claim_id
          this.$store.commit('SET_CLAIM_ID', payload);
          this.$store.commit('SET_IS_OTOPACK', true);
          this.$router.push({ path: '/klaim/success', query: payload })
        } else {
          this.toggleErrorAlert(response.err);
          if (response.err.toLowerCase().match('captcha')) {
            this.getCaptcha();
            this.error.captcha = true;
            this.errorMessage.captcha = response.err;
          }
        }
      }
    },

    async createNewClaim() {
      this.isLoading = true;
      // if already to send database
      if (this.isUserValid.isFinalValidation) {
        const payload = new FormData();

        payload.append('customer_email', this.form.claimer_email);
        payload.append(
          'customer_phone_number',
          `+62${this.form.claimer_only_phone_number}`,
        );
        payload.append(
          'customer_no_telephone',
          this.form.claimer_phone_number
            ? `+62${this.form.claimer_phone_number}`
            : this.form.claimer_phone_number,
        );
        payload.append('claimer_type', this.form.claimer_type[0].value);
        payload.append('package_id', this.form.stt_id.toUpperCase());
        payload.append(
          'ktp_image',
          this.form.attachment_photo_ktp_url[1],
        );
        payload.append(
          'bank_account_image',
          this.form.attachment_photo_bank_url[1],
        );
        payload.append('is_otopack', this.form.is_otopack);
        payload.append('captcha', this.form.captcha);

        if (this.form.claim_type[0].value.toLowerCase() === OVERDUE_TYPE) {
          payload.append('claim_type', CLAIM_TYPE.MBG);
          payload.delete('bank_account_image');
          payload.delete('ktp_image');
          payload.delete('customer_email');
        } else if (this.form.claim_type[0].value.toLowerCase() === MISSING_TYPE) {
          payload.append('claim_type', CLAIM_TYPE.MISSING);
          payload.append(
            'invoice_image',
            this.form.attachment_invoice_url[1],
          );
          payload.append(
            'package_image1',
            this.form.attachment_photo_packet_url[1],
          );
        } else {
          payload.append('claim_type', CLAIM_TYPE.DEMAGE);
          payload.append(
            'invoice_image',
            this.form.attachment_invoice_url[1],
          );
          this.photoDemageCollection.forEach((data, i) => {
            payload.append(`package_image${i + 1}`, data);
          });
        }

        if (this.form.is_otopack) {
          payload.append('stnk_image1', this.form.attachment_stnk_photo_front[0]);
          payload.append('stnk_image2', this.form.attachment_stnk_photo_back[0]);
          payload.delete('package_image1');
          payload.delete('invoice_image');
        }

        if (this.isUserValid.isFinalValidation && this.ButtonSubmitType !== 'disable') {
          this.ButtonSubmitType = 'disable';
          this.buttonText = 'Loading...';
          const response = await ClaimUseCase.create(payload);

          this._setResponse(response);
          onCheckbox('remove', 'submit-checkbox');
          this.isUserValid.isFinalValidation = false;
        }

        this.buttonText = 'Ajukan Klaim';
      }
      this.isLoading = false;
    },
    resetData() {
      this.form = {
        is_otopack: false,
        claimer_email: '',
        claimer_phone_number: '',
        claimer_only_phone_number: '',
        claimer_type: [],
        stt_id: '',
        claim_type: [],
        attachment_photo_ktp_url: [],
        attachment_photo_bank_url: [],
        attachment_invoice_url: [],
        attachment_photo: [],
        attachment_photo_demage_packet: [],
        attachment_photo_packet_url: [],
        attachment_stnk_photo_front: [],
        attachment_stnk_photo_back: [],
        captcha: '',
      };
      this.error = {
        ...initialData.error,
      };
      this.$store.commit('SET_IS_OTOPACK', false);
      onCheckbox('remove', 'submit-checkbox');
      this.isUserValid.isFinalValidation = false;
    },
    toggleErrorAlert(errMsg = '') {
      this.isErrorAlertOpen = !this.isErrorAlertOpen;
      this.errorAlertMessage = errMsg;
      if (this.isErrorAlertOpen) {
        setTimeout(() => {
          this.isErrorAlertOpen = false;
        }, 5000);
      }
    },
    isClaimTypeNotOverdue() {
      if (this.form.claim_type.length) {
        return this.form.claim_type[0].value.toLowerCase() !== OVERDUE_TYPE;
      }
      return true;
    },
    isMBGType(inputValue) {
      if (this.form.claim_type.length) {
        return this.form.claim_type[0].value.toLowerCase() === OVERDUE_TYPE ? true : inputValue;
      }
      return inputValue;
    },
    async getCaptcha() {
      this.isLoadingCaptcha = true;
      const { data, err } = await ClaimUseCase.getCaptcha();
      this.isLoadingCaptcha = false;
      this.form.captcha = '';
      if (data) {
        this.captchaImage = data;
      }
      if (err) {
        this.error.captcha = true;
        this.errorMessage.captcha = err;
      }
    },
    onInput(event) {
      if (event[0].value === 'Keterlambatan') {
        this.form.claimer_type = [
          {
            label: 'Pengirim',
            value: 'Pengirim',
          },
        ];
        this.isClaimMBG = true;
        this.error.claimer_type = false;
      } else {
        this.isClaimMBG = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
