<template>
  <div class="base-form-track">
    <h1>Lacak Klaim</h1>
    <div>
      <div>
        <Input
          v-model="trackByClaimId"
          name="lacak-claim"
          placeholder="Masukan no. klaim"
          label="No. Klaim"
          custom-placeholder="placeholder-lg"
          :required="true"
        />
      </div>
      <Button
        class="custom-padding"
        text="Cek Status"
        :custom-padding="true"
        :type-class="!trackByClaimId ? 'disable' : 'red'"
        @click="getClaimById"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/components/new-button/Button.vue';
import Input from '../../components/Input/index.vue';
import API from "../../app/usecase/claim";
export default {
  components: {
    Input,
    Button
  },
  data() {
    return {
      trackByClaimId: ''
    }
  },
  methods: {
    async getClaimById() {
      if (this.trackByClaimId.length > 0) {
        await API.getId(this.trackByClaimId);
        this.$router.push({
          name: "Lacak Klaim",
          query: { claim_id: this.trackByClaimId },
        });
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
