<template>
  <div class="base-content">
    <!-- FFoto STNK for otopack -->
    <div class="base-content__stnk-image">
      <div
        v-if="data.length"
        class="img-item"
      >
        <img
          :src="src"
          alt=""
        >
        <div
          class="icon-trash"
          @click="onRemoveIamge"
        >
          <img
            src="/images/trashIcon.svg"
            alt=""
          >
        </div>
      </div>
      <div
        v-else
        :class="['base-content__stnk-image__wrapper ', {borderError: isError}]"
        :tabindex="tabindex"
        @keypress="onEnter"
        @click="addSTNKPhoto"
      >
        <input
          ref="file2"
          style="display: none"
          accept="image/jpg, image/png, image/jpeg"
          type="file"
          @change="onChangeImage"
          @click="(event) => (event.target.value = null)"
        >
        <span class="plus">+</span>
      </div>
    </div>

    <!-- description -->
    <div
      v-if="!isError"
      class="base-content__stnk-image__field-desc"
    >
      <span>{{ note }}</span>
    </div>

    <!-- if error -->
    <div
      v-if="isError"
      class="base-content__stnk-image__field-error"
    >
      <span>{{ errorMessage }}</span>
    </div>
    <!-- End Foto Barang Demage packet -->
  </div>
</template>

<script>
import {resizeImage} from '@/misc/imageResize';
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    errorMessage: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: String,
      default: '-1'
    },
    note: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      data: this.value,
      src: ''
    };
  },
  watch: {
    value(val) {
      this.data = val;
    },
  },
  mounted() {
    if (this.value.length) {
      this.src = URL.createObjectURL(this.value[0]) 
    }
  },
  methods: {
    addSTNKPhoto(event) {
      this.$emit('click')
      this.$refs.file2.click();
      event.target.value = '';
    },
    async onChangeImage(event) {
      const file = event.target.files[0]
      if (file.size > 600000) { // is file size more than 600KB
        const resizedImage = await resizeImage({ // it will compress
          file,
          maxSize: 650 // pixel, width or height
        })
        const url = URL.createObjectURL(resizedImage)
        this.src = url        
        this.$emit('change', [resizedImage])
      } else {
        const url = URL.createObjectURL(file)
        this.src = url
        this.$emit('change', [file])
      }
    },
    onRemoveIamge() {
      this.$emit('remove')
      this.src = ''
    },
    onEnter(event) {
      event.preventDefault();
      if (event.key === 'Enter' || event.key === ' ') {
        this.addSTNKPhoto(event);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/style.scss";
.base-content {
  padding: 0px !important;

  &__stnk-image {
    display: flex;
    gap: 10px;
    position: relative;

    &__wrapper {
      cursor: pointer;
      padding: 35px 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px dashed $color-gray-pensive-5;
      width: 249px;
      height: 104px;
      border-radius: 5px;

      @include for-size(mobile) {
        width: 136px;
        height: 104px;
      }

      @media (max-width: 345px) {
        width: 90px;
        height: 90px;
      }

      .plus {
        font-size: 30px;
        font-weight: 400;
        color: $color-gray-shade;
      }
    }

    &__field-desc {
      > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $color-gray-shade;
      }
    }

    &__field-error {
      > span {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: $color-base;
      }
    }
  }
}

.img-item {
  width: 249px;
  height: 104px;

  @include for-size(mobile) {
    width: 136px;
    height: 104px;
  }

  overflow: hidden;
  border-radius: 8px;
  position: relative;

  > img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
}

.icon-trash {
  top: 40%;
  right: 40%;
  position: absolute;
  padding: 5px;
  cursor: pointer;
}

.borderError {
  border: 2px dashed $color-base;
  border-radius: 5px;
}
</style>
