export const CLAIM_TYPE = {
  MISSING: 'LOST',
  DEMAGE: 'DAMAGED',
  MBG: 'OVERDUE',
};

export const OVERDUE_TYPE = 'keterlambatan';
export const DAMAGE_TYPE = 'rusak';
export const MISSING_TYPE = 'hilang';


// error message

export const ERROR_MESSAGE = {
  // if field is empety
  IS_STT_EMPTY: 'Nomor resi atau STT harus diisi',
  IS_CLAIMER_TYPE_EMPTY: 'Klaimer harus diisi',
  IS_ONLY_PHONE_NUMBER_EMPTY: 'Nomor handphone harus diisi',
  IS_CLAIMER_EMAIL_EMPTY: 'Email harus diisi',
  IS_ATTACHMENT_BANK_EMPTY: 'Foto buku tabungan harus diupload',
  IS_ATTACHMENT_KTP_EMPTY: 'Foto KTP harus diupload',
  IS_CLAIM_TYPE_EMPTY: 'Tipe klaim harus diisi',
  IS_ATTACHMENT_INVOICE_EMPTY: 'Foto bukti invoice harus diupload',
  IS_ATTACHMENT_DEMAGE_PACKET_EMPTY: 'Foto barang harus diupload',
  IS_CAPTCHA_EMPTY: 'Captcha Harus Diisi',
  IS_ATTACHMENT_STNK_PHOTO_FRONT_EMPTY: 'Foto Sisi Depan STNK Harus Diupload',
  IS_ATTACHMENT_STNK_PHOTO_BACK_EMPTY: 'Foto Sisi Belakang STNK Harus Diupload',

  // if field is not valid
  IS_STT: 'Nomor resi atau STT salah, cek kembali',
  IS_ONLY_PHONE_NUMBER: 'Nomor handphone salah, cek kembali',
  IS_CLAIMER_EMAIL: 'Email salah, cek kembali',
  IS_ATTACHMENT_DEMAGE_PACKET: 'Foto barang tidak boleh melebihi dari 2 MB',
  IS_PHONE_NUMBER: 'Nomor telepon salah, cek kembali',
};
