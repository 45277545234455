<template>
  <div
    class="base-dropdown"
    style="margin-top: 24px;"
  >
    <div class="base-dropdown__label">
      <span>{{ label }}</span>
      <span class="star">*</span>
    </div>
    <dropdown
      :options="options"
      :value="value"
      :placeholder="placeholder"
      :required="true"
      :is-remove-shadow="true"
      :is-error="isError"
      :disabled="disabled"
      @open="$emit('focus')"
      @input="onInput"
    />
    <div
      v-if="isError"
      class="base-dropdown__error-message"
    >
      <img
        :src="`/images/icon_danger_triangles.svg`"
        alt=""
      >
      <span>{{ errorMessage }}</span>
    </div>
  </div>
</template>

<script>
import Dropdown from '@/components/new-dropdown/Dropdown.vue';

export default {
  components: {
    Dropdown,
  },
  props: {
    options: {
      type: Array,
    },
    value: {
      type: Array
    },
    label: {
      type: String,
      default: ''
    },
    isError: {
      type: Boolean,
      default: true
    },
    errorMessage: {
      type: String,
      default: 'this field is required'
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  methods: {
    onInput(event) {
      this.$emit('input', event)
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/style.scss';

.base-dropdown {
  position: relative;
  line-height: 21px;
  color: $color-base-text;

  &__label {
    margin-bottom: 8px;
    >span{
      font-size: 14px;
      font-weight: 600;
    }
  }

  .star {
    color: $color-base;
    margin-left: 4px;
  }

  &__error-message {
    display: flex;
    gap: 5px;
    >span {
      color: $color-red-6;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
  }
}

.border-error {
  border: 1px solid $color-red-6;
}
</style>
